import React, { useEffect, useState, useRef } from "react";
import axios from 'axios';
import { format } from 'date-fns';

const SectionOne = () => {
    const [diaryContent, setDiaryContent] = useState("")
    const [diary, setDiary] = useState([])
    const [num, setNum] = useState(0)
    const [userIP, setUserIP] = useState("")
    const bottomRef = useRef(null);

    const scrollToBottom = () => {
        bottomRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    };

    const getUserIP = async () => {
        try {
            const response = await axios.get("http://154.204.34.110:5002/api/getip")

            setUserIP(response.data)
        } catch {

        }
    }

    const publishDiary = async () => {
        if (diaryContent.replace(/\s+/g, '').length === 0) {
            alert("请输入内容")

            return
        }

        if (diaryContent.length > 1000) {
            alert("长度不能超过1000个字符")

            return
        }

        try {
            const response = await axios.post("http://154.204.34.110:5002/api/publish", { diaryContent })

            if (response.status === 201) {
                setDiaryContent("")
                getDiary();
            }
        } catch {
            alert("发送消息出错，请稍后再试")
        }
    }

    const getDiary = async () => {
        try {
            const response = await axios.get("http://154.204.34.110:5002/api/diaryinfo")

            setNum(num + 1)
            setDiary(response.data)
        } catch {

        }
    }

    // useEffect(() => {
    //     getDiary()
    // }, [diary])

    useEffect(() => {
        getUserIP()

        const intervalId = setInterval(() => {
            getDiary();
        }, 2000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    useEffect(() => {
        scrollToBottom()
    }, [diary.length])

    return (
        <div className="SectionOne">
            <div className="SectionOne-div-01">
                <h1 style={{ color: "#9945FF" }}>聊天广场</h1>
                <div className="SectionOne-div-01-01">
                    {diary.map((d) => (
                        <div key={d.id} className={"SectionOne-div-01-01-01 " + (d.ip === userIP ? "d-flex flex-column align-items-end" : "d-flex flex-column align-items-start")}>
                            <div className="SectionOne-title">
                                {d.ip} <span style={{ marginLeft: "10px" }}>{format(new Date(d.publish_date), 'yyyy/MM/dd HH:mm:ss')}</span>
                            </div>

                            <div className="SectionOne-content">
                                <h3>{d.content}</h3>
                            </div>
                            <br />
                        </div>
                    ))}

                    <div ref={bottomRef}></div>
                </div>
            </div>
            <br />
            <div className="SectionOne-div-02">
                <h1>发送消息</h1>

                <div className="SectionOne-div-03">
                    {userIP}
                    <textarea className="SectionOne-textarea-01" value={diaryContent} onChange={(e) => setDiaryContent(e.target.value)} placeholder="请输入内容..."></textarea>
                    <br />
                    当前已写 <span style={{ color: "red" }}>{diaryContent.length}</span> 字符，最长可写 <span style={{ color: "red" }}>1000</span> 字符
                </div>
                <button onClick={publishDiary} className="SectionOne-button-01">发送</button>
            </div>
        </div>
    )
}

export default SectionOne