import "bootstrap/dist/css/bootstrap.min.css"
import './App.css';
import SectionOne from './components/SectionOne/SectionOne';

function App() {
  return (
    <div className="App">
      <SectionOne />
    </div>
  );
}

export default App;
